import * as UI from '@openstax/ui-components';
import { AssignmentManagementStates } from '../hooks/grades';
import {
  FetchState, stateHasData, FetchStateType,
} from "@openstax/ts-utils/fetch";
import React from 'react';
import styled from 'styled-components';
import { OldToolTip, TooltipWrapper } from '../../components/ToolTip';
import { ButtonRow, LastSyncContainer, StyledTooltipGroup } from "./Settings/styles";
import useMatchMobileQuery from '../../utils/reactUtils';

const formatTimestamp = (timestamp: number) =>
  new Date(timestamp).toLocaleString(undefined, {dateStyle: 'short', timeStyle: 'short'});

const stateIsLoading = <T, E>(state: FetchState<T, E>) => state.type === FetchStateType.LOADING;

export const LastSyncMessage = (props: { lastSync?: string }) => props.lastSync ?
  <span>Last manual sync: <strong>{props.lastSync}</strong></span> : null;

export const SyncExportScores = styled((props: {
  assignmentManagementStates: AssignmentManagementStates;
}) => {
  const {
    assignmentAttemptsState, downloadState, syncScoresState, registrationMetadataState,
    downloadCsv, postSyncScores,
  } = props.assignmentManagementStates;

  const showNoScoresTooltip = stateHasData(assignmentAttemptsState) && assignmentAttemptsState.data.length === 0;
  const disabled = showNoScoresTooltip || stateIsLoading(downloadState) || stateIsLoading(syncScoresState);

  const lastSync = React.useMemo(
    () => stateHasData(registrationMetadataState) && registrationMetadataState.data.lastScoresSync
      ? formatTimestamp(registrationMetadataState.data.lastScoresSync)
      : undefined
  , [registrationMetadataState]);

  const isMobile = useMatchMobileQuery();

  return <>
    <ButtonRow justifySpaceBetween={!lastSync}>
      {/* TO-DO: Replace old tooltips with new react-aria component from ui-components lib */}
      <TooltipWrapper>
        {showNoScoresTooltip ? <OldToolTip placement='bottom'>No grades available.</OldToolTip> : null}

        <UI.DropdownMenu
          disabled={disabled}
          id='grades-menu'
          text='Sync/Export Grades'
          variant='secondary'
          width='100%'>
            <UI.DropdownMenuItem onAction={() => postSyncScores(false)}>
              Sync only completed grades to LMS
            </UI.DropdownMenuItem>
            <UI.DropdownMenuItem onAction={() => postSyncScores(true)}>
              Sync all grades to LMS (including partial)
            </UI.DropdownMenuItem>
            <UI.DropdownMenuItem onAction={downloadCsv}>
              Export grades to .csv
            </UI.DropdownMenuItem>
        </UI.DropdownMenu>
      </TooltipWrapper>

      <StyledTooltipGroup placement={isMobile ? 'bottom' : 'right'}>
        All manually synced grades, including partial grades,<br/>
        will be interpreted as final grades by the LMS.<br/>
        Dropped student scores are available via CSV export only.
      </StyledTooltipGroup>
    </ButtonRow>
    {lastSync ? <LastSyncContainer><LastSyncMessage lastSync={lastSync}/></LastSyncContainer> : null}
  </>;
})`
`;
