import React from 'react';
import { FetchStateType } from '@openstax/ts-utils/fetch';
import { useUserMetadata } from '../../user/utils/user-metadata';
import { initializePI } from '../../utils/pulseinsights';

export const useInitializePI = () => {
  const [userMetadata] = useUserMetadata();

  React.useEffect(() => {
    if (userMetadata.type === FetchStateType.SUCCESS) {
      const createdAssignmentsCount = userMetadata.data.createdAssignmentsCount ?? 0;
      initializePI({ createdAssignmentsCount, role: 'instructor' });
    }
  }, [userMetadata]);
};
