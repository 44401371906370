import { stateHasData } from "@openstax/ts-utils/fetch";
import type {ActivityState} from '@openstax/ts-utils/services/lrsGateway/attempt-utils';
import type {MappedUserInfo} from '@openstax/ts-utils/services/accountsGateway';
import styled from 'styled-components';
import * as UI from '@openstax/ui-components';
import type {
  AssignmentReadResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import { AttemptProgressLabel } from "./AttemptProgressLabel";
import { AssignmentManagementStates } from "../hooks/grades";
import { SyncExportScores } from "./SyncExportScores";
import type {
  Score
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/utils/xapiUtils";
import { Toasts } from "../../toasts/ToastContext";
import { LastSyncContainer } from './Settings/styles';

const dateFormat = (timestamp: string | undefined) => timestamp
  ? new Date(timestamp).toLocaleString(undefined, {dateStyle: 'short', timeStyle: 'short'})
  : '';

const scoreFormat = (score: Score | undefined) => [
  score?.raw
    ? `${score.raw}/${score.max}`
    : '',
  score?.scaled
    ? `${(score.scaled * 100).toFixed(2)}%`
    : ''
].filter(s => !!s).join(' ');

const AttemptsTable = styled.table`
  font-size: 2rem;
  color: ${UI.colors.palette.neutralThin};
  width: 100%;
  padding: 1rem;

  tr {
    border-bottom: 1px solid ${UI.colors.palette.neutralThin};
  }
  tr:last-child {
    border-bottom: none;
  }

  td {
    padding: 1rem;
  }
  tr > .status {
    text-align: center;
  }
  tr > .date {
    text-align: right;
  }
  tr > .actions {
    text-align: right;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
`;

const SyncExportScoresWrapper = styled.div`
  ${LastSyncContainer} {
    padding: 0.8rem 0 0 0.5rem;
    margin: 0;
    text-align: left;
  }
`;

export const AssignmentSubmissionList = ({assignment, assignmentManagementStates, onSelectAttempt, onEditAssignment}: {
  assignment: AssignmentReadResponse;
  assignmentManagementStates: AssignmentManagementStates;
  onEditAssignment?: () => void;
  onSelectAttempt: (attempt: MappedUserInfo<ActivityState>) => void;
}) => {
  const attempts = assignmentManagementStates.assignmentAttemptsState;

  if (!stateHasData(attempts)) {
    return <UI.Loader />;
  }

  return <>
    <Toasts />
    <Header>
      <SyncExportScoresWrapper>
        <SyncExportScores assignmentManagementStates={assignmentManagementStates} />
      </SyncExportScoresWrapper>
      {onEditAssignment
        ? <UI.Button variant="primary" onClick={onEditAssignment}>Edit Assignment</UI.Button>
        : null
      }
    </Header>
    <AttemptsTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Grade</th>
          <th className="date">Started</th>
          <th className="date">Completed</th>
          <th className="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        {attempts.data.map(attempt => <tr key={attempt.uuid}>
          <td>{attempt.fullName}</td>
          <td className='status'>
            <AttemptProgressLabel attempt={attempt.data} />
          </td>
          <td>{scoreFormat(attempt.data.currentAttemptCompleted?.result?.score)}</td>
          <td className="date">{dateFormat(attempt.data.currentAttempt?.timestamp)}</td>
          <td className="date">{dateFormat(attempt.data.currentAttemptCompleted?.timestamp)}</td>
          <td className="actions">
            <UI.ButtonLink onClick={() => onSelectAttempt(attempt)}>Details</UI.ButtonLink>
          </td>
        </tr>)}
      </tbody>
    </AttemptsTable>
  </>;
};
