/* spell-checker: ignore Previewable */
import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import {
  IntegrationSearchResultItem,
} from '@project/lambdas/build/src/services/activityConfigResolver';
import {
  IntegrationActivity
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/types";
import { renderRouteUrl } from "../../core";
import { ActivitiesDisplay } from "../../components/ActivitiesDisplay";
import { useInitializePI } from '../hooks/InitializePI';
import { useServices } from "../../core/context/services";
import { WithRequired } from "@openstax/ts-utils/types";
import { Error } from '@openstax/ui-components';

const PreviewMessageType = 'PREVIEW_ACTIVITY_SET';
const PreviewReadyMessageType = 'PREVIEW_ACTIVITY_SET_READY';

type PreviewableActivity = WithRequired<IntegrationSearchResultItem<IntegrationActivity>, 'preview'>;

type PreviewableActivityPayload = {activities: PreviewableActivity[]; type: typeof PreviewMessageType};
type PreviewReadyPayload = {type: typeof PreviewReadyMessageType};

const isPreviewActivitiesMessage = (e: MessageEvent<any>): e is MessageEvent<PreviewableActivityPayload> =>
  e.data.type === PreviewMessageType
;
const isPreviewReadyMessage = (e: MessageEvent<any>): e is MessageEvent<PreviewReadyPayload> =>
  e.data.type === PreviewReadyMessageType
;

export const useOpenPreviewActivities = () => {
  const authProvider = useServices().authProvider;

  return React.useCallback((activities: PreviewableActivity[]) => {
    authProvider.getUser().then(() => {
      const previewWindow = window.open(
        authProvider.getAuthorizedLinkUrl(
          window.location.origin + renderRouteUrl(activitySetPreviewScreen, undefined)
        )
      );

      const handleCallback = (e: MessageEvent<any>) => {
        if (isPreviewReadyMessage(e) && e.source && e.source === previewWindow) {
          e.source.postMessage({type: PreviewMessageType, activities}, window.location.origin);
        }
      };
      window.addEventListener('message', handleCallback, false);
    });
  }, [authProvider]);
};

export const usePreviewActivities = () => {
  const [activities, setActivities] = React.useState<PreviewableActivity[]>();

  React.useEffect(() => {
    const handleCallback = (e: MessageEvent<any>) => {
      if (isPreviewActivitiesMessage(e)) {
        setActivities(e.data.activities);
        window.removeEventListener('message', handleCallback);
      }
    };
    window.addEventListener('message', handleCallback, false);
    window.opener?.postMessage({type: PreviewReadyMessageType});

    return () => window.removeEventListener('message', handleCallback);
  }, []);

  return activities;
};

const PreviewExpiredError = () => <Error heading="Preview Expired">
  Please close this page and open the preview link again.
</Error>;

export const ActivitySetPreview = () => {
  useInitializePI();
  const authProvider = useServices().authProvider;
  const activities = usePreviewActivities();
  const [currentActivityIndex, setCurrentActivityIndex] = React.useState<number>(0);
  const currentActivity = activities?.[currentActivityIndex];

  return window.opener ? <ActivitiesDisplay
    sidebar={(activities || []).map((activity, index) => ({
      onClick: () => { setCurrentActivityIndex(index); },
      id: index.toString(),
      active: index === currentActivityIndex,
      icon: 'icon' in activity ? activity.icon?.url : undefined,
      title: activity.title
    }))}
  >
    {currentActivity ? <iframe
      style={{flex: 1}}
      title={currentActivity.title}
      src={authProvider.getAuthorizedEmbedUrl(currentActivity.preview.url)} /> : null}
  </ActivitiesDisplay> : <PreviewExpiredError />;
};

export const activitySetPreviewScreen = createRoute({name: 'ActivitySetPreview', path: '/instructor/activity-preview',
  handler: makeScreen(ActivitySetPreview)
});
