import React from 'react';
import {
  AssignmentReadResponse, CreateAssignmentStepPayload
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import {
  fetchLoading, fetchIdle, FetchState,
  stateHasData, stateHasError,
} from "@openstax/ts-utils/fetch";
import { FilterOrnTypes, filterResourceContents, AnyOrnResource } from "@openstax/orn-locator";
import * as UI from '@openstax/ui-components';
import {
  applyTopicSortSync,
} from '@project/lambdas/build/src/services/activityConfigResolver';
import {
  GlobalStyle, Main, ActionBar, SubContent,
  MainContent, ModalBody, ModalButtons, StyledSidebarNav
} from './styles';
import { SupportResourceSelect } from "../SupportResourceSelect";
import { useOrn } from "../../utils/orn-utils";
import { ContentActivitySelect } from "../ContentActivitySelect";
import { ContentPreview } from '../ContentPreview';
import { renderRouteUrl } from '../../../core';
import { launchScreen } from '../../../student/screens/Launch';
import { useServices } from "../../../core/context/services";
import Warning from '../../../components/Warning';
import ButtonGroup from '../ButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import { FlattenedActivityStep, stepIsRemoved, hasStep } from "../../utils/activity-step-utils";
import { warnings } from '../../constants/messages';
import { AssignmentOptions, useAssignmentOptions } from '../../hooks/options';
import styled from 'styled-components';
import EditableHeading from '../EditableHeading';
import { setContentMetadata } from "../../../utils/dataLayer";
import { useLaunchTokenData } from "../../../auth/useTokenData";
import { usePushToast, Toasts } from "../../../toasts/ToastContext";
import { SyncExportScores } from "../SyncExportScores";
import { AssignmentManagementStates } from "../../hooks/grades";
import syncIcon from "../../../assets/icons/sync.svg";
import builderIcon from "../../../assets/icons/builder.svg";
import useMatchMobileQuery from '../../../utils/reactUtils';
import { BodyPortalSlotsContext } from '@openstax/ui-components';
import HelpMenu from '../../../components/HelpMenu';
import { useInitializePI } from '../../hooks/InitializePI';

const isAllowedContent = (content: AnyOrnResource) => {
  return content.type === 'book:page'
    && 'tocType' in content
    && content.tocType === 'book-content'
    && ['preface', 'intro', 'numbered-section'].includes(content.tocTargetType)
  ;
};
const isAllowedSubScope = (content: AnyOrnResource): content is FilterOrnTypes<AnyOrnResource, 'book:subbook'> => {
  return content.type === 'book:subbook'
    && 'tocType' in content
    && content.tocType === 'chapter'
  ;
};

type SubScopeItem = {subScope: FilterOrnTypes<AnyOrnResource, 'book:subbook'>; contents: string[]};
type SubScopeList = SubScopeItem[];

const findSubScopes = (scope: AnyOrnResource, carry: string[] = []): SubScopeList => {
  const scopeContents: AnyOrnResource[] = 'contents' in scope ? scope.contents : [];

  const reduced = scopeContents.reduce(
    (result, item: AnyOrnResource) => {
      if (isAllowedContent(item)) {
        return {...result, carry: [...result.carry, item.orn]};
      }
      if (isAllowedSubScope(item)) {
        const contents = filterResourceContents(item, ['book:page'])
          .filter(isAllowedContent)
          .map(resource => resource.orn)
        ;

        // don't even show subScopes with no items
        if (contents.length < 1) {
          return result;
        }

        return {carry: [], scopes: [
          ...result.scopes,
          {subScope: item, contents: [...result.carry, ...contents]}
        ]};
      }

      const subResults = findSubScopes(item, result.carry);

      if (subResults.length < 1) {
        return result;
      }

      return {carry: [], scopes: [...result.scopes, ...subResults]};
    },
    {carry, scopes: []} as {carry: string[]; scopes: SubScopeList}
  );

  const tail = reduced.scopes.slice(-1)[0];

  if (reduced.carry.length > 0 && tail) {
    tail.contents.push(...reduced.carry);
  }

  return reduced.scopes;
};

const useSubScopes = (orn: string | undefined) => {
  const loadState = useOrn(orn);
  const [subScopeState, setSubScopeState] = React.useState<
    FetchState<ReturnType<typeof findSubScopes>, string>
  >(
    orn ? fetchLoading() : fetchIdle()
  );

  React.useEffect(() => {
    setSubScopeState(stateHasData(loadState)
      ? {...loadState, data: findSubScopes(loadState.data)}
      : loadState as FetchState<ReturnType<typeof findSubScopes>, string>
    );
  }, [loadState]);

  return subScopeState;
};

const prepareFlatActivitiesForSave = (steps: FlattenedActivityStep[]): CreateAssignmentStepPayload[] => {
  return steps
    .filter(step => !stepIsRemoved(step))
    .map(step => step.activity);
};

export type ActivityUpdatePayload = {
  selected?: FlattenedActivityStep[];
  unselected?: FlattenedActivityStep[];
};
export type ActivityUpdateHandler = (
  doChange: ActivityUpdatePayload | ((existing: FlattenedActivityStep[]) => ActivityUpdatePayload)
) => void;

const PickerContainer = styled.div`
  border-top: 1px solid ${UI.colors.palette.pale};
  padding-top: 1.6rem;
`;

const BaseSelectScopeContent = ({
  showPreview,
  setShowPreview,
  navBarIsVisible,
  ...props
}: React.ComponentProps<typeof SelectScopeContent> & {
  showPreview: boolean; setShowPreview: React.Dispatch<boolean>;
  navBarIsVisible: boolean;
}) => {
  const assignmentData = 'assignmentData' in props ? props.assignmentData : undefined;

  // TODO - if activitiesByOrn is set on props.data, then we are editing an old
  // assignment, we should process activitiesByOrn into a new flatActivities that
  // includes the attachedTo and stepId fields
  //
  // in this case its ok to discard the existing flatActivities because old assignments
  // can't have any important metadata on the flatActivities yet
  const [flatActivities, setFlatActivities] = React.useState<FlattenedActivityStep[]>(
    assignmentData?.activities ?? []
  );
  const scope = useOrn(props.scope);
  const services = useServices();
  const launchTokenData = useLaunchTokenData();
  const subScopes = useSubScopes(props.scope);
  const assignmentOptions = useAssignmentOptions(assignmentData, flatActivities.filter(step => !stepIsRemoved(step)));
  const [subScope, setSubScope] = React.useState<'support' | SubScopeItem>('support');
  const [firstSelectedResource, setFirstSelectedResource] = React.useState<boolean>(false);
  const [showWarning, setShowWarning] = React.useState<boolean>(false);
  const id = assignmentData?.id;
  const pushToast = usePushToast();
  const onShowSubmissions = props.onShowSubmissions;

  React.useEffect(() => {
    if (stateHasData(scope) && subScope !== 'support') {
      setContentMetadata(subScope.subScope, scope.data);
    } else if (stateHasData(scope)) {
      // there is no content selected but we want to record the scope, so
      // use the scope as the content. can clean up when contentId is not required
      setContentMetadata(scope.data, scope.data);
    }
  }, [subScope, scope]);

  const isCopiedAssignment = React.useMemo(() => !!launchTokenData.contextId
    && assignmentData !== undefined
    && (launchTokenData.contextId !== assignmentData.contextId?.replace(/_([^_]+)$/, '')),
    [launchTokenData, assignmentData]
  );

  const launchLink = React.useMemo(() => props.editView && id
    ? new URL(renderRouteUrl(launchScreen, { id }), window.location.href).href :
    '', [id, props.editView]);

  const subScopeCounts = React.useMemo(() => {
    if (!stateHasData(subScopes) || flatActivities.length === 0) {
      return {};
    }

    const countStepsForTopics = (contents: string[]) => flatActivities.reduce(
      // only count steps here that are not "attached to" other steps, meaning they were added
      // via an addon checkbox. not sure this distinction is going to fly forever but it seems ok
      // for now
      (count, step) => stepIsRemoved(step) || step.activity.attachedTo
        ? count
        : Object.keys(step.topics).some(topic => contents.includes(topic)) ? count + 1 : count
    , 0);

    return subScopes.data.reduce((counts, resource) => {
      counts[resource.subScope.orn] = countStepsForTopics([resource.subScope.orn, ...resource.contents]);
      return counts;
    }, {} as {[key: string]: number});
  }, [flatActivities, subScopes]);

  const totalSubScopeCounts = React.useMemo(() => {
    return Object.values(subScopeCounts).reduce((total, value) => total + value, 0);
  }, [subScopeCounts]);

  const [hasCustomOrder, setHasCustomOrder] = React.useState(assignmentData?.customOrder || false);

  React.useEffect(() => {
    // if activities are cleared, reset customOrder flag
    if (flatActivities.length === 0) {
      setHasCustomOrder(false);
      setShowPreview(false);
    }
  }, [flatActivities, setShowPreview]);

  const handleBack = () => {
    setShowPreview(false);
    setFirstSelectedResource(false);
  };

  const handleLaunch = (e: any) => {
    e.preventDefault();
    window.open(services.authProvider.getAuthorizedLinkUrl(launchLink), '_blank', 'noreferrer');
  };

  const handlePreview = () => {
    setShowPreview(true);
    setFirstSelectedResource(false);
  };

  const handleSave = () => props.onSave(
    prepareFlatActivitiesForSave(flatActivities),
    hasCustomOrder,
    assignmentOptions.assignmentOptions
  );

  // assignments from copied courses cannot be modified so the select screen is not rendered
  React.useEffect(() => setShowPreview(isCopiedAssignment), [isCopiedAssignment, setShowPreview]);

  React.useEffect(() => {
    if (stateHasData(subScopes) && !firstSelectedResource) {
      const firstResource = subScopes.data.find(resource =>
        subScopeCounts[resource.subScope.orn] > 0
      );
      if (firstResource) {
        setSubScope(firstResource);
        setFirstSelectedResource(true);
      }
    }
  }, [subScopes, subScopeCounts, firstSelectedResource]);

  const updateActivities: ActivityUpdateHandler = (input) => {

    const handler = (previous: FlattenedActivityStep[], change: ActivityUpdatePayload) => {
      const afterUnselect = 'unselected' in change
        ? previous.map(previousItem =>
          hasStep(change.unselected, previousItem) ? {...previousItem, removed: true} : previousItem
        )
        : previous
      ;
      const afterSelect = 'selected' in change
        ? afterUnselect.map(previousItem =>
          hasStep(change.selected, previousItem) ? {...previousItem, removed: false} : previousItem
        )
        : afterUnselect;

      const unattachedNewItems = 'selected' in change && change.selected
        ? change.selected.filter(selectedItem =>
          !selectedItem.removed && !hasStep(previous, selectedItem) && !selectedItem.activity.attachedTo
        )
        : [];

      const attachedNewItems = 'selected' in change && change.selected
        ? change.selected.filter(selectedItem =>
          !selectedItem.removed && !hasStep(previous, selectedItem) && selectedItem.activity.attachedTo
        )
        : [];

      if (!stateHasData(scope)) {
        throw new Error('should not be possible to update chosen activities before scope loads');
      }

      // if there is a custom order, attached items are added next to their subject steps, while new single
      // elements are added at the end (themselves sorted, but not modifying the sort of stuff above)
      //
      // if there is no custom order, we just throw everything in a list and sort it
      return hasCustomOrder
        ? [...afterSelect.flatMap(selected => {
          const attachers = attachedNewItems.filter(item => item.activity.attachedTo === selected.activity.stepId);
          return applyTopicSortSync(scope.data, [selected, ...attachers]);
        }), ...applyTopicSortSync(scope.data, unattachedNewItems)]
        : applyTopicSortSync(scope.data, [...attachedNewItems, ...afterSelect, ...unattachedNewItems])
      ;
    };

    setFlatActivities(previous => {
      const change = typeof input === 'function'
        ? input(previous)
        : input;
      return handler(previous, change);
    });
  };

  const selectedTitle = (length: number) => `${length} item${length > 1 ? 's' : ''} selected`;
  const subScopeAriaTitle = (html: string, itemCount: number) => {
    const title = new DOMParser()
    .parseFromString(html, "text/html")
    .body.textContent?.replace(/\s\s+/g, " ")
    .trim() || "";
    return `${title} ${itemCount > 0 ? `- ${selectedTitle(itemCount)}` : ''}`;
  };

  const supportScopes = React.useMemo(() => [
    props.scope,
    ...props.parents
  ], [props.scope, props.parents]);

  React.useEffect(() => {
    if (props.showEditToast) {
      pushToast({
        title: 'Success',
        message: 'Your changes have been saved',
        variant: 'success',
        dismissAfterMs: 5000
      });
    }
  }, [props.showEditToast, pushToast]);

  const isMobile = useMatchMobileQuery();

  return <>
    <UI.Modal show={showWarning} heading={'Leave without saving?'} onModalClose={() => setShowWarning(false)}>
      <ModalBody className='inner'>
        {'Are you sure you want to exit this page? Your changes will not be saved.'}
        <ModalButtons>
          <UI.Button variant="light" onClick={() => setShowWarning(false)}>Stay</UI.Button>
          <UI.Button onClick={props.back}>Leave anyway</UI.Button>
        </ModalButtons>
      </ModalBody>
    </UI.Modal>
    <Toasts />
    {!showPreview ? <StyledSidebarNav
      isMobile={isMobile}
      actionBarIsVisible={subScope !== "support"}
      navBarIsVisible={navBarIsVisible}
      id="sidebar-nav"
    >
      {({ navIsCollapsed, setNavIsCollapsed }) => <>
        <header>
          {props.editView && props.assignmentManagementStates ? <>
            <h1 aria-label="Grade sync">
              <button
                {...!navIsCollapsed && { tabIndex: -1 }}
                onClick={() => {
                  setNavIsCollapsed(!navIsCollapsed);
                }}
                aria-label={navIsCollapsed ?
                  "Click to view Grade Sync"
                  : "Click to collapse navigation"}
                aria-controls="sidebar-nav"
              >
                <img src={syncIcon} alt="" />
                <span>{navIsCollapsed ? 'Grades' : 'Grade sync'}</span>
              </button>
            </h1>
            {!navIsCollapsed ? <SyncExportScores
              assignmentManagementStates={props.assignmentManagementStates}
            /> : null}</> : null}
          <h1
            aria-label={
              `Assignment Builder ${isMobile && totalSubScopeCounts ?
                ` - ${totalSubScopeCounts} items selected` : ''}`
            }
          >
            <button
              {...!navIsCollapsed && { tabIndex: -1 }}
              onClick={() => {
                setNavIsCollapsed(!navIsCollapsed);
              }}
              aria-label={navIsCollapsed ?
                "Click to view Assignment Builder"
                : "Click to collapse navigation"}
              aria-controls="sidebar-nav"
            >
              <img src={builderIcon} alt="" />
              <span>{navIsCollapsed ? 'Builder' : 'Assignment builder'}</span>
              {navIsCollapsed && totalSubScopeCounts ?
                <small
                  aria-hidden="true"
                  className={`${totalSubScopeCounts > 99 ? 'wide' : ''}`}
                >
                  {Math.min(totalSubScopeCounts, 99)}
                  {totalSubScopeCounts > 99 ? <sup>+</sup> : null}
                </small>
                : null}
            </button>
          </h1>
        </header>
        <SubContent>
          {stateHasError(subScopes) ? <strong>{subScopes.error}</strong> : null}
          {!navIsCollapsed ? <ol>
            <li>
              <button
                className={` transparent${subScope === 'support' ? ' selected' : ''}`}
                aria-current={subScope === 'support' ? 'page' : 'false'}
                onClick={e => setSubScope('support')}
              >
                Support
              </button>
            </li>
            {stateHasData(subScopes)
              ? subScopes.data.map(item =>
                <li key={item.subScope.orn}>
                  <button
                    className={`transparent${subScope === item ? ' selected' : ''}`}
                    aria-current={subScope === item ? 'page' : 'false'}
                    aria-label={subScopeAriaTitle(item.subScope.title, subScopeCounts[item.subScope.orn])}
                    onClick={() => {
                      setSubScope(item);
                      setNavIsCollapsed(isMobile);
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.subScope.title }} />
                    {subScopeCounts[item.subScope.orn] > 0
                      ? <small
                        title={selectedTitle(subScopeCounts[item.subScope.orn])}
                      >{subScopeCounts[item.subScope.orn]}</small>
                      : null
                    }
                  </button>
                </li>
              )
              : <UI.Loader delay={500} />
            }
          </ol> : null}
        </SubContent></>}
    </StyledSidebarNav> : null}
    <MainContent aria-live="polite" navMobile={isMobile} navVisible={!showPreview}>
      {props.editView
        ? <Warning messages={isCopiedAssignment ? [warnings.editCopy] : [warnings.editOriginal]} />
        : <Warning messages={[warnings.create, warnings.copy]} />
      }
      {subScope === 'support'
        ? null
        : <EditableHeading title={props.assignmentTitle} handleEdit={props.onUpdateTitle} loading={!!props.loading} />
      }
      {!props.editView && stateHasData(scope)
        ? <Breadcrumbs
          warningHandler={() => setShowWarning(true)}
          items={[{ text: 'title' in scope.data ? scope.data.title : '' },]}
        />
        : null}
      {subScope === 'support'
        ? <SupportResourceSelect scopes={supportScopes} />
        : <PickerContainer>
          {showPreview
            ? <ContentPreview
              assignmentManagementStates={props.assignmentManagementStates}
              defaultFlatActivities={flatActivities.filter(step => !stepIsRemoved(step))}
              assignmentOptions={assignmentOptions}
              setFlatActivities={setFlatActivities}
              setHasCustomOrder={setHasCustomOrder}
              editingDisabled={isCopiedAssignment}
            />
            : <ContentActivitySelect
              scope={subScope.subScope}
              selectedContent={subScope.contents}
              selectedActivities={flatActivities}
              onChange={updateActivities}
            />
          }
        </PickerContainer>
      }
    </MainContent>
    <ActionBar
      hide={subScope === 'support'}
      flexEnd={!onShowSubmissions && (!showPreview || isCopiedAssignment)}
    >
      {showPreview
        ? (isCopiedAssignment
          ? <ButtonGroup right={{ handler: handleLaunch, text: 'View as student' }} />
          : <ButtonGroup
            left={{ handler: handleBack, text: 'Back' }}
            middle={!!props.editView ? { handler: handleLaunch, text: 'View as student' } : undefined}
            right={{ handler: handleSave, text: props.editView ? 'Update' : 'Create', disabled: props.loading }}
          />)
        : <ButtonGroup
          left={onShowSubmissions ? {
            handler: onShowSubmissions,
            text: 'Back',
          } : undefined}
          middle={{ handler: () => setFlatActivities([]), text: 'Clear selection' }}
          right={{
            handler: handlePreview,
            text: 'Review assignment',
            disabled: flatActivities.length === 0 || props.loading
          }}
        />
      }
    </ActionBar>
  </>;
};

export const SelectScopeContent = (props: {
  scope: string;
  parents: string[];
  onSave: (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions,
  ) => void;
  back?: () => void;
  loading?: boolean;
  editView?: boolean;
  showEditToast?: boolean;
  onUpdateTitle?: (title: string) => void;
  assignmentTitle: string;
  assignmentManagementStates?: AssignmentManagementStates;
  onShowSubmissions?: () => void;
} & (
    { scopeData: AnyOrnResource } |
    { assignmentData: AssignmentReadResponse }
  )) => {

  useInitializePI();
  const launchTokenData = useLaunchTokenData();
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const showNavBar = launchTokenData.branding !== false;
  const id = 'assignmentData' in props ? props.assignmentData.id : undefined;

  return <BodyPortalSlotsContext.Provider value={[
    'nav',
    'sidebar',
    'main',
    'root'
  ]}>
    <GlobalStyle />
    {showNavBar ? <UI.NavBar logo={true}><HelpMenu id={id} /></UI.NavBar> : null}
    <Main
      navVisible={!showPreview}
      slot="main"
      tagName="main"
    >
      <BaseSelectScopeContent
        {...props}
        navBarIsVisible={showNavBar}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />
    </Main>
  </BodyPortalSlotsContext.Provider>;
};
