import React from "react";
import checkmark from "../assets/icons/checkmark.svg";
import pencil from "../assets/icons/pencil.svg";
import { OldToolTip } from "./ToolTip";
import { BodyPortal, BodyPortalSidebarNav, BodyPortalSlotsContext, breakpoints, colors } from "@openstax/ui-components";
import styled, { createGlobalStyle, css } from "styled-components";
import classNames from "classnames";
import openstaxLogoMobile from "../assets/logo-mobile.svg";
import openstaxLogo from "../assets/logo.svg";
import { SidebarNavStyles } from "@openstax/ui-components";

export const GlobalStyle = createGlobalStyle`
  body {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      "sidebar nav"
      "sidebar main"
      "sidebar footer";
    }
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    [data-portal-slot="sidebar"] {
      grid-area: sidebar;
    }

    [data-portal-slot="nav"] {
      grid-area: nav;
    }

    [data-portal-slot="main"] {
      grid-area: main;
    }

    [data-portal-slot="footer"] {
      grid-area: footer;
    }
  }
`;

const collapsedStyles = css`
  overflow: unset;

  ${SidebarNavStyles.NavBody} {
    padding: 1.6rem 0.8rem;
  }

  ${SidebarNavStyles.NavFooter} {
    padding: 1.6rem 0.8rem 0.8rem;
  }

  .logos {
    margin: 0;
  }

  .logo {
    margin-left: 0;
    max-width: 3.8rem;
    width: 100%;

    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }

  &.collapsed .scope {
    max-width: 4rem;
  }

  .activities {
    align-items: center;
  }

  .activities button .title,
  .activities button .status,
  .activities button .status.complete,
  .scope span {
    display: none;
  }
  .scope img {
    margin-right: 0;
  }
  .activities button .icon {
    margin-right: 0;
  }
`;

export const ActivityNav = styled(BodyPortalSidebarNav)`
  --expanded-width: 29.6rem;
  width: 29.6rem;
  font-size: 1.4rem;
  line-height: 2rem;

  ${SidebarNavStyles.NavFooter} {
    transition: padding 200ms ease-in-out;
  }
  &.collapsed {
    ${collapsedStyles}
  }

  &.expanding {
    .scope span {
      white-space: nowrap;
      overflow: hidden;
    }
    ${SidebarNavStyles.NavBody} {
      overflow: hidden;
    }
  }

  ${SidebarNavStyles.ToggleButton} {
    top: 5.2rem;
  }

  ${SidebarNavStyles.NavBody} {
    overflow-y: auto;
  }

  ${SidebarNavStyles.NavBody}, ${SidebarNavStyles.NavFooter} {
    padding: 1.6rem;
  }

  .logos {
    height: 8.8rem;
    margin: 0 -0.8rem;
    border-bottom: 1px solid ${colors.palette.pale};
  }

  .logo {
    margin-left: 0.8rem;
    width: 10.5rem;
    &.mobile {
      display: none;
    }
  }

  .scope {
    display: flex;
    align-items: center;
    color: ${colors.palette.neutralDarker};
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
    img {
      width: 7.2rem;
      margin-right: 0.8rem;
    }
  }

  button {
    margin-bottom: 1.6rem;
  }

  .activities {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 2.4rem;

    button {
      position: relative;
      color: ${colors.palette.neutralThin};
      display: flex;
      align-items: center;
      margin: 0 0 1.6rem 0;
      padding: 0;
      cursor: pointer;
      background: none;
      border: 0;
      text-align: left;
      line-height: 2rem;

      &.active {
        color: ${colors.palette.mediumBlue};
        .icon {
          background: ${colors.palette.mediumBlue};
        }

        .status {
          border-color: ${colors.palette.mediumBlue};
        }
      }

      &:hover {
        text-decoration: underline;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        width: 2rem;
        margin-right: 0.8rem;
        background: ${colors.palette.neutralThin};
        color: ${colors.palette.white};
        flex-shrink: 0;

        img {
          height: 1.1rem;
        }
      }

      .title {
        flex-grow: 1;
        min-width: 0;
        margin-right: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .status {
        width: 1.6rem;
        height: 1.6rem;
        border: 0.2rem solid ${colors.palette.pale};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &.complete {
          background: ${colors.palette.mediumBlue};
          border-color: ${colors.palette.mediumBlue};

          img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1rem;
          }
        }
      }
    }
  }
`;

const Main = styled(BodyPortal)`
  font-family: "Neue Helvetica W01", Helvetica, Arial, sans-serif;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.mobileNavBreak}em) {
    width: calc(100% - ${SidebarNavStyles.collapsedWidth});
  }
`;

const ActivityWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

const TitleToolTip = styled(OldToolTip)`
  max-width: 20rem;
  white-space: normal;
`;

export const ActivityButton = ({
  title,
  iconUrl,
  onClick,
  className,
  showStatus,
  isComplete,
}: {
  title: string;
  iconUrl: string;
  onClick: () => void;
  className: string;
  showStatus: boolean;
  isComplete: boolean;
}) =>
  <button
    onClick={onClick}
    className={className}>
    <span className="icon">
      {(<img src={iconUrl} aria-hidden={true} alt='' />)}
    </span>
    <div className='title'>
      <span>{title}</span>
      {/* TO-DO: add js to detect which titles are truncated and only show a tooltip in that case */}
      <TitleToolTip className='tooltip'>{title}</TitleToolTip>
    </div>
    {showStatus ? <span className={classNames('status', { complete: isComplete })}>
      {isComplete ? (<img src={checkmark} alt='complete' />) : null}
    </span> : null}
  </button>;

export const ActivitiesDisplay = ({
  sidebar,
  navFooter,
  children,
}: React.PropsWithChildren<{
  navFooter?: React.ReactNode;
  sidebar: {
    title: string;
    id: string;
    icon?: string;
    active: boolean;
    onClick: () => void;
    isComplete?: boolean;
  }[];
}>) => {
  return (<BodyPortalSlotsContext.Provider value={[
    'sidebar',
    'nav',
    'main',
    'footer',
    'root'
  ]}>
    <GlobalStyle />
    <ActivityNav navFooter={navFooter}>
      {({ setNavIsCollapsed, isMobile }) => <>
        <div className="logos">
          <img src={openstaxLogo} alt="OpenStax" className="logo desktop" />
          <img
            src={openstaxLogoMobile}
            alt="OpenStax"
            className="logo mobile"
          />
        </div>
        <div className="activities">
          {sidebar.map((activity) => (
            <ActivityButton
              onClick={() => {
                activity.onClick();
                if (isMobile) {
                  setNavIsCollapsed(true);
                }
              }}
              key={activity.id}
              className={classNames({ active: activity.active })}
              iconUrl={activity.icon || pencil}
              title={activity.title}
              showStatus={"isComplete" in activity}
              isComplete={Boolean(activity.isComplete)}
            />))}
        </div>
      </>
      }
    </ActivityNav>
    <Main tagName="main" slot="main">
      <ActivityWrapper>
        {children}
      </ActivityWrapper>
    </Main>
  </BodyPortalSlotsContext.Provider>);
};
