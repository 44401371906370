import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import omit from 'lodash/fp/omit';
import pencil from '../../../assets/icons/pencil.svg';
import close from '../../../assets/icons/close.svg';
import grab from '../../../assets/icons/grab-handle.svg';
import {
  StepIcon, StyledStep, DeleteButton, Description, GrabHandle,
  StyledSortableItem, StepGroup, StepInfo, StepTitleContainer, Points, NumberInput, StepBody
} from './styles';
import { FlattenedActivityStep } from "../../utils/activity-step-utils";
import { Pill, PillContainer } from "../ContentActivitySelect";
import { Html } from '../Html';

// source: https://stackoverflow.com/a/75859314
const useTruncatedElement = ({ ref }: { ref: React.RefObject<HTMLParagraphElement> }) => {
  const [isTruncated, setIsTruncated] = React.useState(false);
  const [isReadingMore, setIsReadingMore] = React.useState(false);

  React.useEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};
    setIsTruncated(!!(offsetHeight && scrollHeight && offsetHeight < scrollHeight));
  }, [ref]);

  return {
    isTruncated,
    isReadingMore,
    setIsReadingMore,
  };
};

type ItemProps = {
  iconUrl?: string;
  isDragging?: boolean;
  isPrimaryStep?: boolean;
  canEdit?: boolean;
  title: string;
  description: string;
  points: number;
  labels?: string[];
  handlePointsChange: (points: number) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  handleDelete: (item: any) => void;
  listeners: any;
};

export const Item = (props: ItemProps) => {
  const textRef = React.useRef<HTMLParagraphElement>(null);
  const { isTruncated, isReadingMore, setIsReadingMore } = useTruncatedElement({
    ref: textRef,
  });

  const validateAndUpdatePoints = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(e.target.value, 10);
    const clamped = isNaN(parsed) ? 0 : Math.min(Math.max(parsed, 0), 100);
    e.target.value = clamped.toString();
    props.handlePointsChange(clamped);
  };

  return <StyledStep isDragging={props.isDragging} showHandle={props.isPrimaryStep && props.canEdit}>
    {props.canEdit
      ? <DeleteButton onClick={props.handleDelete} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
          <img src={close} alt='delete activity step' />
        </DeleteButton>
    : null}
    {props.canEdit && props.isPrimaryStep
      ? <GrabHandle {...props.listeners}><img src={grab} alt='drag activity' /></GrabHandle>
      : null
    }
    {props.iconUrl
      ? <StepIcon>{(<img src={props.iconUrl} aria-hidden={true} alt='' />)}</StepIcon>
      : null}
    <StepBody>
      <StepInfo>
        <StepTitleContainer>
          <h4><Html>{props.title + ' '}</Html></h4>
          <PillContainer>
            {props.labels && props.labels.length > 0 ? <Pill>{props.labels[0]}</Pill> : null}
          </PillContainer>
        </StepTitleContainer>
        {props.description ? <Description isReadingMore={isReadingMore}>
          <p ref={textRef}>{props.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
          {isTruncated
            ? <button
              onClick={() => setIsReadingMore(!isReadingMore)}
              aria-expanded={isReadingMore}
            >
              {isReadingMore ? 'Less' : 'More'}
            </button>
            : ''}
        </Description> : null}
      </StepInfo>
      <Points>
        {props.canEdit
          ? <label><NumberInput name='points' type='number' pattern='[0-9]*' min={0} max={100}
            defaultValue={props.points} onBlur={validateAndUpdatePoints} /> points</label>
          : `${props.points} points`
        }
      </Points>
    </StepBody>
  </StyledStep>;
};

export const SortableItem = (props: {
  id: string;
  steps: FlattenedActivityStep[];
  handleDelete: (item: FlattenedActivityStep[]) => void;
  handlePointsChange: (groupId: string, stepId: string, maxPoints: number) => void;
  editingDisabled?: boolean;
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id, disabled: props.editingDisabled});
  // By default, sortable items are buttons, but ours should not be. They
  // contain buttons that are accessible for re-ordering.
  const attributesWithoutDescription = omit([
    'aria-roledescription', 'role', 'tabIndex', 'aria-disabled', 'aria-describedby'
  ], attributes);

  // mouse events to show deleted state on non-primary group steps
  // when the primary delete button is hovered. this could be done
  // in css with `:has()` but firefox doesn't support it
  const [showDelete, setShowDelete] = React.useState(false);
  const [showDeleteActive, setShowDeleteActive] = React.useState(false);

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <StyledSortableItem ref={setNodeRef} style={style} {...attributesWithoutDescription}>
      <StepGroup isDragging={isDragging} showDelete={showDelete} showDeleteActive={showDeleteActive}>
        {props.steps.map((step) => {
          const {title, description, resultHash, activity} = step;

          // TODO - no this type check. maybe topic type = learning?
          const isPrimaryStep = props.steps.length === 1 || step.activity.type === 'reading';

          return <Item
            isDragging={isDragging}
            isPrimaryStep={isPrimaryStep}
            listeners={listeners}
            key={resultHash}
            iconUrl={step.icon?.url || pencil}
            title={title}
            labels={activity.labels}
            description={description || ''}
            points={activity.maxPoints}
            canEdit={!props.editingDisabled}
            handlePointsChange={(points) => props.handlePointsChange(props.id, activity.stepId, points)}
            onMouseEnter={isPrimaryStep ? () => {
              setShowDeleteActive(true);
              setShowDelete(true);
            } : undefined}
            onMouseLeave={isPrimaryStep ? () => {
              setShowDeleteActive(false);
              setShowDelete(false);
            } : undefined}
            handleDelete={() => {
              props.handleDelete(isPrimaryStep ? props.steps : [step]);
              setShowDelete(false);
              setShowDeleteActive(false);
            }}
          />;
        })}
     </StepGroup>
    </StyledSortableItem>
  );
};
