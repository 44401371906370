import React from "react";
import {
  NavBarMenuButton,
  NavBarMenuItem,
  colors,
} from "@openstax/ui-components";
import { useServices } from "../core/context/services";
import newTabIcon from "../assets/icons/new-tab.svg";
import styled from "styled-components";

const overlayContent = `
  width: 80rem;
  max-width: calc(100vw - 6rem);
  height: 80vh;
`;
export const styledMenu = {
  Button: styled(NavBarMenuButton)`
    color: ${colors.palette.gray};
  `,
  Item: styled(NavBarMenuItem)`
    color: ${colors.palette.neutralDarker};
    text-decoration: none;

    :focus-visible {
      outline: 0;
      background: ${colors.palette.neutralLighter};
    }
  `,
  Document: styled.div`
    ${overlayContent}
    background-color: ${colors.palette.white};
    color: ${colors.palette.neutralDarker};
    padding: 2rem 3rem;
  `,
};

function useUserId() {
  const [id, setId] = React.useState<number | undefined>();
  const authProvider = useServices().authProvider;
  
  React.useEffect(
    () => {
      authProvider.getUser().then((userData) => userData && setId(userData.id));
    },
    [authProvider]
  );  

  return id;
}

export default function HelpMenu({id}: { id?: string }) {
  const userId = useUserId();
  const contactFormUrl = React.useMemo(() => {
    const formUrl = "https://openstax.org/contact";
    const params = [
      id ? encodeURIComponent(`assignment_id=${id}`) : "",
      userId ? encodeURIComponent(`user_id=${userId}`) : "",
    ]
      .filter((s) => s.length)
      .map((p) => `body=${p}`)
      .join("&");

    return `${formUrl}?${params}`;
  }, [id, userId]);

  return (
    <>
      <styledMenu.Button label="Help">
        <styledMenu.Item
          href={contactFormUrl}
          target="_blank"
        >
          Report an issue
        </styledMenu.Item>
        <styledMenu.Item
          href="https://help.openstax.org/s/global-search/assignable"
          target="_blank"
        >
          Support center&nbsp;
          <img src={newTabIcon} alt="new tab" />
        </styledMenu.Item>
        <styledMenu.Item
          href="https://openstax.org/accessibility-statement"
          target="_blank"
        >
          Accessibility statement&nbsp;
          <img src={newTabIcon} alt="new tab" />
        </styledMenu.Item>
      </styledMenu.Button>
    </>
  );
}
