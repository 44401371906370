/* eslint-disable max-len */
export const recoveryCredentialsMessages = {
  unlinked: {
    title: 'Save your highlights and notes with OpenStax',
    body: "Create an OpenStax account to retain access to your highlights and notes even after your course ends. It's completely free!",
  },
  linked: {
    title: 'Setup complete.',
    body: 'You will now be able to access your notes and highlights after this course ends.',
  },
  declined: {
    title: 'Preference saved.',
    body: 'Your notes and highlights will be deleted after this course ends.',
  },
};
