import { JsonCompatibleStruct } from '@openstax/ts-utils/routing';

declare global {
  interface Window {
    dataLayer: JsonCompatibleStruct[];
  }
}

// There's very little info in the docs on what's accepted as a value here
export type PIContext = { role: 'instructor' | 'student' } & JsonCompatibleStruct;

export const initializePI = async(context: PIContext) => {
  window.dataLayer?.push({event: 'user_stats', 'os.user_stats': context});
};
