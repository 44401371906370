import React from "react";
import { BrowserServices } from "../..";
import { renderRouteUrl } from "../../core";
import { recoveryCredentialsCompleteScreen } from "../screens/RecoveryCredentialsComplete";
import { useRecoveryCredentialsCompleteTrigger } from "./usePostMessage";

export const useCreateCredentialsPopup = ({
  accountsHost,
  authProvider,
  onComplete,
}: {
  accountsHost: string;
  authProvider: BrowserServices["authProvider"];
  onComplete?: () => void;
}) => {
  const popupHandle = React.useRef<Window | null>();
  const { protocol, host } = window.location;
  const hostWithProtocol = `${protocol}//${host}`;
  const routeUrl = renderRouteUrl(recoveryCredentialsCompleteScreen, undefined);
  const returnTo = hostWithProtocol + encodeURIComponent(routeUrl);

  const openPopup = React.useCallback(() => {
    const originalUrl = authProvider.getAuthorizedLinkUrl(
      `${accountsHost}/external_user_credentials/new?return_to=${returnTo}`,
    );

    const url = new URL(originalUrl);
    const params = url.searchParams;

    const authValue = params.get("auth");
    if (authValue !== null) {
      params.delete("auth");
      params.set("token", authValue);
    }

    url.search = params.toString();
    const newUrl = url.toString();

    popupHandle.current = window.open(
      newUrl,
      "linkAccountWindow",
      "popup,width=508,height=820",
    );
  }, [accountsHost, authProvider, returnTo]);

  const closePopup = () => {
    popupHandle.current?.close();
  };

  useRecoveryCredentialsCompleteTrigger(() => {
    closePopup();

    if (onComplete) {
      onComplete();
    }
  });

  return { openPopup, closePopup };
};
