import { Button, Modal, ModalBody, ModalCard } from "@openstax/ui-components";
import { UserMetadataPayload } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/user";
import React from "react";
import styled from "styled-components";

const ConfirmModal = styled(Modal)`
  ${ModalCard} {
    max-width: 54.2rem;
    width: 100%;
  }

  ${ModalBody} {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
`;

const ConfirmButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmDeclineRecoveryCredentials = ({
  saveUserMetadata,
  setShow,
  setShowConfirm,
}: {
  setShow: (value: boolean) => void;
  saveUserMetadata: (payload: UserMetadataPayload) => void;
  setShowConfirm: (value: boolean) => void;
}) => {
  const storeDecline = React.useCallback(() => {
    saveUserMetadata({ recoveryCredentialsDeclined: true });
    setShow(false);
  }, [saveUserMetadata, setShow]);

  return (
    <ConfirmModal
      heading="Delete all notes and highlights?"
      onModalClose={() => setShow(false)}
      show={true}
    >
      <ModalBody>
        <p>
          Are you sure you want to permanently delete your highlights and notes
          after this course ends?
        </p>
        <p>Once deleted, files cannot be restored.</p>
        <ConfirmButtons>
          <Button variant="light" onClick={storeDecline}>
            Yes, delete my files after this course ends
          </Button>
          <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
        </ConfirmButtons>
      </ModalBody>
    </ConfirmModal>
  );
};
