import React from "react";
import { createRoute, makeScreen } from "../../core/services";
import classNames from "classnames";
import pencil from "../../assets/icons/pencil.svg";
import {
  BodyPortal,
  Button,
  ButtonLink,
  colors,
  Error,
  Loader,
  SidebarNavStyles,
  H2,
} from "@openstax/ui-components";
import styled, { createGlobalStyle } from "styled-components";
import { useUserMetadata } from "../../user/utils/user-metadata";
import { FetchStateType, stateHasData } from "@openstax/ts-utils/fetch";
import { useAccountsBase, useAuth } from "../../auth/useAuth";
import { ConfirmDeclineRecoveryCredentials } from "../components/RecoveryCredentials/ConfirmDeclineRecoveryCredentials";
import { useCreateCredentialsPopup } from "../hooks/useRecoveryCredentials";
import { useServices } from "../../core/context/services";
import { BrowserServices } from "../..";
import { recoveryCredentialsMessages } from "../constants/messages";
import useMatchMobileQuery from "../../utils/reactUtils";
import {
  ActivityButton,
  ActivityNav,
} from "../../components/ActivitiesDisplay";
import openstaxLogoMobile from "../../assets/logo-mobile.svg";
import openstaxLogo from "../../assets/logo.svg";

export const GlobalStyle = createGlobalStyle`
  body {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "sidebar main";
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    [data-portal-slot="sidebar"] {
      grid-area: sidebar;
    }

    [data-portal-slot="main"] {
      grid-area: main;
    }
  }

  #root {
    display: none;
  }
`;

const Main = styled(BodyPortal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden auto;
`;

const Header = styled(H2)`
  margin-bottom: 0.8rem;
  line-height: 4rem;
  letter-spacing: -1.44px;
`;

const Wrapper = styled.div<{ isMobile?: boolean }>`
  align-self: normal;
  padding: 8.8rem 7.2rem 4rem 4rem;
  max-width: 64rem;
  ${({ isMobile }) =>
    isMobile ? `margin-left: ${SidebarNavStyles.collapsedWidth};` : null}

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 0.8rem 0 1.6rem;
    color: ${colors.palette.neutralThin};
    padding-right: 4rem;
  }

  ${ButtonLink} {
    text-decoration: underline;
    text-align: left;
    font-size: 1.6rem;
  }
`;

const ButtonWrapper = styled.div`
  margin: 2.4rem 0;
`;

const Screen = ({
  title,
  body,
  children,
}: {
  title: string;
  body: string;
  children?: React.ReactNode;
}) => {
  const isMobile = useMatchMobileQuery();
  return (
    <Wrapper isMobile={isMobile}>
      <Header as="h1">{title}</Header>
      <p>{body}</p>
      {children}
    </Wrapper>
  );
};

const UnlinkedScreen = ({
  setLinked,
  setShowConfirmDecline,
  accountsHost,
  authProvider,
}: {
  setLinked: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmDecline: React.Dispatch<React.SetStateAction<boolean>>;
  accountsHost: string;
  authProvider: BrowserServices["authProvider"];
}) => {
  const { openPopup } = useCreateCredentialsPopup({
    accountsHost,
    authProvider,
    onComplete: () => setLinked(true),
  });

  return (
    <Screen
      title={recoveryCredentialsMessages.unlinked.title}
      body={recoveryCredentialsMessages.unlinked.body}
    >
      <ButtonWrapper>
        <Button onClick={openPopup}>Sign up</Button>
      </ButtonWrapper>
      <ButtonLink onClick={() => setShowConfirmDecline(true)}>
        I don't want to save my highlights and notes
      </ButtonLink>
    </Screen>
  );
};

const LinkedScreen = () => (
  <Screen
    title={recoveryCredentialsMessages.linked.title}
    body={recoveryCredentialsMessages.linked.body}
  />
);

const DeclinedScreen = () => (
  <Screen
    title={recoveryCredentialsMessages.declined.title}
    body={recoveryCredentialsMessages.declined.body}
  />
);

export const RecoveryCredentialsTask = () => {
  const isMobile = useMatchMobileQuery();
  const authProvider = useServices().authProvider;
  const accountsBase = useAccountsBase();
  const user = useAuth();
  const [userMetadata, saveUserMetadata] = useUserMetadata();
  const [linked, setLinked] = React.useState(false);
  const [showConfirmDecline, setShowConfirmDecline] = React.useState(false);

  const declined = React.useMemo(
    () =>
      userMetadata.type === FetchStateType.SUCCESS &&
      stateHasData(userMetadata) &&
      userMetadata.data.recoveryCredentialsDeclined === true,
    [userMetadata],
  );

  React.useEffect(() => {
    setLinked(
      user.type === FetchStateType.SUCCESS &&
        stateHasData(user) &&
        userMetadata.type === FetchStateType.SUCCESS &&
        "contact_infos" in user.data &&
        user.data.contact_infos.length !== 0,
    );
  }, [userMetadata, user]);

  const allFetchSuccess =
    user.type === FetchStateType.SUCCESS &&
    userMetadata.type === FetchStateType.SUCCESS &&
    accountsBase.type === FetchStateType.SUCCESS;

  const errorState = [user, userMetadata, accountsBase].find(
    (state) => state.type === FetchStateType.ERROR,
  );

  if (errorState && "error" in errorState) {
    return <Error>{errorState.error}</Error>;
  }

  return (
    <>
      <GlobalStyle />
      <ActivityNav isMobile={isMobile}>
        {({ setNavIsCollapsed }) => (
          <>
            <div className="logos">
              <img src={openstaxLogo} alt="OpenStax" className="logo desktop" />
              <img
                src={openstaxLogoMobile}
                alt="OpenStax"
                className="logo mobile"
              />
            </div>
            <div className="activities">
              <ActivityButton
                title="Save your Highlights and notes"
                onClick={() => {
                  setNavIsCollapsed(isMobile);
                }}
                className={classNames({ active: true })}
                iconUrl={pencil}
                showStatus={true}
                isComplete={linked || declined}
              />
            </div>
          </>
        )}
      </ActivityNav>
      <Main tagName="main" slot="main">
        {allFetchSuccess ? (
          declined ? (
            <DeclinedScreen />
          ) : linked ? (
            <LinkedScreen />
          ) : (
            <UnlinkedScreen
              setLinked={setLinked}
              setShowConfirmDecline={setShowConfirmDecline}
              authProvider={authProvider}
              accountsHost={accountsBase.data}
            />
          )
        ) : (
          <Loader />
        )}
        {showConfirmDecline ? (
          <ConfirmDeclineRecoveryCredentials
            setShowConfirm={setShowConfirmDecline}
            saveUserMetadata={saveUserMetadata}
            setShow={setShowConfirmDecline}
          />
        ) : null}
      </Main>
    </>
  );
};

export const recoveryCredentialsTaskScreen = createRoute({
  name: "RecoveryCredentialsTaskScreen",
  path: "/recovery-credentials-task",
  handler: makeScreen(RecoveryCredentialsTask),
});
