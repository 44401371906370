import {launchScreen} from './Launch';
import {callbackScreen} from './Callback';
import { recoveryCredentialsCompleteScreen } from './RecoveryCredentialsComplete';
import { recoveryCredentialsTaskScreen } from './RecoveryCredentialsTask';

export const studentRoutes = () => ([
  launchScreen,
  callbackScreen,
  recoveryCredentialsCompleteScreen,
  recoveryCredentialsTaskScreen
]);
